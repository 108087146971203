*{
    -webkit-tap-highlight-color: transparent;
}
*::-webkit-scrollbar {
    display: none;
  }
  
  /* Hide scrollbar for IE, Edge and Firefox */
* {
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
  }
body {
    /* background-image: url(../../imgs/bg.png);
    background-size: contain; */
    font-family: Arial, sans-serif;
    /* background-color: #000; */
    /* color: #fff; */
    margin: 0;
    padding: 0;
}

.modal {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.8);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
}

.modalContent {
  background: white;
  padding: 20px;
  border-radius: 8px;
  max-width: 600px;
  max-height: 100%;
  overflow-y: auto;
  position: relative;
}

.closeButton {
  position: absolute;
  top: 20px;
  left: 10px;
  background: transparent;
  border: none;
  font-size: 16px;
  cursor: pointer;
}

.imageContainer {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

/* .imageContainer img {
  width: 100%;
} */

.modalImage {
  max-width: 100%;
  border-radius: 8px;
}

.download {
    position: absolute;
    right: 20px;
    display: flex;
    background: #FFCB19;
    gap: 4px;
    padding-block: 8px;
    padding-inline: 30px;
    border-radius: 10px;
    align-items: center;
    cursor: pointer;
}

.download_android {
    height: 27px;
    width: 27px;
    display: flex;
}

.download_title {
    font-size: 19px;
    font-weight: 600;
    color: black;
}

.notecontainer{
    margin: 16px;
    display: grid;
    gap: 5px;
}

.notetitle{
  font-weight: 600;
}

.titledesc {
    font-weight: 600;
    font-size: 18px;
    position: absolute;
    right: 50px;
    top: 70px;
    color: #FFCC19;
    cursor: pointer;
}

.mainlogo{
    /* height: 6.5rem;
    width: 5.5rem; */
    margin: 0 16px;
    /* background-image: url(../images/maomilogo_new.png); */
    /* background-repeat: no-repeat;
    background-position: 0, .64rem;
    background-size: contain; */
    display: flex;
}

.mainlogo .title_wrapper{
    padding-left: 16px;
    display: flex;
    flex-direction: column;
}


.mainlogo .title_wrapper .title{
   margin: 0;
   font-size: 2.5rem;
   font-weight: bold;
}

.mainlogo .title_wrapper .sub_title{
 margin: 4px 0;
 font-size: 18px;
}


.bgpage {
    background-image: linear-gradient(rgb(116 62 64 / 78%), rgb(116 62 64 / 77%)), url('/public/images/bg.png');
    background-size: contain;
    max-width: 600px;
    margin: 0 auto;
    padding-top: 20px;
    padding-bottom: 36px;
    /* background: #000000cf; */
    position: relative;
    /* display: none; */
}

.csicon {
  position: fixed;
  bottom: 40px;
  right: 20px;
  z-index: 999;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.container {
    display: flex;
    align-items: center;
    background-color: rgb(208, 132, 163, 0.8); /* Pink background */
    padding: 20px;
    border-radius: 10px; /* Rounded corners */
    /* width: 90%; */
    margin: 30px 16px; /* Center horizontally */
    gap: 40px;
    text-align: center;
  }

  .center {
    display: flex;
    align-items: center;
    gap: 30px;
    margin: 0 auto;
  }
  
  .item {
    text-align: center;
    color: white;
  }
  
  .title {
    font-size: 18px;
    font-weight: 500;
  }
  
  .value {
    font-size: 24px;
    font-weight: 600;
    padding-top: 5px;
  }
  
  .divider {
    width: 1px;
    height: 40px;
    background-color: white;
  }
  

.slider {
    display: flex;
    /* overflow-x: scroll; */
    overflow: auto;
    padding-left: 16px;
    gap: 12px;
}

.slider .slider_wrapper {
    /* width: 56%; */
    text-align: center;
    border-radius: 10px;
    /* padding: 10px; */
    /* padding-left: 14px; */
}

.slider .slider_wrapper img {
    width: max-content;
    border-radius: 16px;
    max-height: 530px;
    max-width: 400px
}


.install {
    color: #333;
    padding: 14px;
}

.install h1 {
    text-align: left;
    margin-bottom: 20px;
    color: #fff;
    font-size: 24px;
    font-weight: 600;
}

.install {
    margin: 0 auto;
    border-radius: 10px;
    text-align: center;
    font-family: Arial, sans-serif;
  }
  
  .install title {
    font-size: 24px;
    color: #ffffff;
    margin-bottom: 20px;
    font-weight: bold;
  }
  
  /* Card Styling */
  .card {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: rgba(243, 154, 184, 0.6);
    border-radius: 8px;
    padding: 10px 20px;
    margin-bottom: 8px;
  }
  
  .card:last-child {
    margin-bottom: 0;
  }
  
  .icon img {
    display: flex;
    align-items: center;
    height: 30px;
    width: 30px;
  }
  
  .text {
    flex: 1;
    font-size: 18px;
    /* font-weight: bold; */
    color: #ffffff;
    text-align: left;
    margin-left: 10px;
  }
  
  .button {
    background-color: #ffffff;
    /* color: rgba(208, 132, 163, 0.9); */
    border: none;
    border-radius: 5px;
    padding: 8px 20px;
    font-size: 16px;
    /* font-weight: bold; */
    cursor: pointer;
    transition: background-color 0.3s ease, color 0.3s ease;
  }
  
  .button:hover {
    background-color: rgba(208, 132, 163, 0.9);
    color: #ffffff;
  }

  .qrcode {
    height: 130px;
    width: 130px;
  }

  .download_container { 
    text-align: center;
  }

  .download_btn {
    margin-top: 50px !important;
    /* width: 90%; */

    margin: 0 14px;
    height: 60px; /* Define explicit height */
    background-image: url('/public/images/download2.png');
    background-size: cover; /* Adjust image to fit */
    background-repeat: no-repeat; /* Prevent repeat */
    border-radius: 10px;
  
    /* Flexbox for vertical and horizontal alignment */
    display: flex;
    justify-content: center; /* Center horizontally */
    align-items: center; /* Center vertically */
    cursor: pointer;
  }
  

  .qrcodetitle {
    font-size: 16px;
  }
  

@media screen and (min-width: 750px) {
    .csicon {
        right: calc(50vw - 280px);
    }
}


@media screen and (max-width: 750px) {
  .download_container { 
    display: none;
  }
  .download_btn{
    height: 46px;
  }
  .download {
    position: absolute;
    right: 20px;
    top: 14px;
    background: #FFCB19;
    gap: 4px;
    padding-block: 6px;
    padding-inline: 20px;
    border-radius: 10px;
    cursor: pointer;
}
  .qrcodetitle {
    font-size: 14px;
  }
  .button {
    background-color: #ffffff;
    color: black;
    border: none;
    border-radius: 5px;
    padding: 6px 15px;
    font-size: 12px;
    font-weight: 500;
    cursor: pointer;
    transition: background-color 0.3s ease, color 0.3s ease;
  }
  .install h1 {
    text-align: left;
    margin-bottom: 20px;
    color: #fff;
    font-size: 18px;
    font-weight: 600;
}

  .text {
    flex: 1;
    font-size: 14px;
    /* font-weight: bold; */
    color: #ffffff;
    text-align: left;
    margin-left: 10px;
  }

    .slider .slider_wrapper img {
        width: max-content;
        border-radius: 10px;
        max-height: 365px;
        max-width: 250px;
    }
    .title {
        font-size: 14px;
        font-weight: 500;
      }
      
      .value {
        font-size: 18px;
        font-weight: 600;
      }
    .download_title {
        font-size: 16px;
        font-weight: 600;
        color: black;
    }
    
    .titledesc {
        font-size: 14px;
        font-weight: 600;
        position: absolute;
        right: 43px;
        top: 54px;
        color: #FFCC19;
    }

    .container {
        display: flex;
        align-items: center;
        background-color: rgb(208, 132, 163, 0.8); /* Pink background */
        padding: 20px;
        border-radius: 10px; /* Rounded corners */
        margin: 30px 16px; /* Center horizontally */
        text-align: center;
      }
      
    .center {
        display: flex;
        align-items: center;
        gap: 15px;
        margin: 0 auto;
      }

    .page[data-v-1e026c99] .seal-3-wrapper {
        width: 80%;
    }

      
    .mainlogo .title_wrapper .title{
        font-size: 24px;
       }

    .mainlogo .title_wrapper .sub_title{
    font-size: 14px;
   }

   .page[data-v-1e026c99] .seal-f img {
    width: 74px;
    }

    .page[data-v-1e026c99] .seal-f-text {
        font-size: 1rem;
    }
}
