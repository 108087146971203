.content {
    position: absolute;
    width: 100%;
}
.redexpire {
    color: red;
    font-weight: 700;
}
.mt110 {
    margin-top: 110px;
}
.btm {
    margin-top: 39px;
    gap: 30px;
}