.arrow{
    rotate: 180deg;
    width: 15px;
    filter: opacity(0.5);
}
.direct_link{
    cursor: pointer;
    font-weight: 600;
    color: rgb(231, 121, 150);
    display: flex;
    justify-content: center;
    gap: 2px;
    font-size: 18px;
}
.sub_link{
    color: #e77996;
    font-weight: 500;
}
.header_title{
    display: flex;
    font-size: 21px;
    margin-bottom: 2px !important;
}
.ts {
    grid-template-columns: 1fr;
}
.ts_logo {
    width: 80px;
    height: 80px;
    border-radius: 50px;
}
.grid_url {
    display: grid;
    grid-template-columns: repeat(10, minmax(0, 1fr));
    gap: 5px;

}
.grid_heiliao {
    display: grid;
    grid-template-columns: repeat(2, minmax(0, 1fr));
    gap: 5px;

}
.grid_heiliao_outside {
    display: grid;
    grid-template-columns: repeat(2, minmax(0, 1fr));
    gap: 5px;

}
.ts_title {
    color: #242424;
    font-size: 18px;
    font-weight: 700;
}
.line {
    height: 1px;
    width: 100%;
    background: #E7E7E7;
}

.ts_container {
    border: 1px solid #ECECEC;
    background-color: white;
    padding: 20px;
    display: grid;
    align-items: center;
    grid-template-columns: auto 1fr 120px;
    gap: 30px;
}
.new_container {
    padding: 0 10px;
}
.desc {
    margin-top: 10px;
    font-size: 14px;
    font-weight: 400;
    color: #666666;
}
.left_cover {
    /* margin-top: auto; */
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 30px;
}
.ml250 {
    margin-left: 250px;
}
.main1 {
    display: none;
}
.ts_detail {
    color: #454545;
    font-size: 12px;
    font-weight: 400;
}
.ts_de_img {
    border-radius: 50px;
}
.ts_sin_vid {
    display: none;
}

.urlbox {
    text-align: center;
    align-items: center;
    font-size: 16px;
    font-weight: 600;
    background: linear-gradient(180deg, #FB8EB7, #EAA6C7, #D9BFD9);
    -webkit-background-clip: text; /* For Safari */
    background-clip: text;
    color: transparent; /* Make the text color transparent so the gradient shows */
}

.withtransparency {
    display: block;
    position: relative;
  }
  .withtransparency:before {
    content:"";
    position: absolute;
    inset: 0;
    /* padding: 1px;  */
    border: 1px solid #666;
    background: #666666;
    border-radius: 10px;
    --m:conic-gradient(#000 0 0);
    mask: var(--m) content-box exclude,var(--m);
    pointer-events: none;
  }

.list_fushou {
    display: grid;
    grid-template-columns: repeat(13, minmax(0, 1fr));
    place-items: center; /* Center items horizontally and vertically */

}

.truncate {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.listinfo {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(100px, 1fr)); /* Adjust min width as needed */
    /* place-items: center; */
    gap: 20px;
    width: 1200px;
    margin: auto;

}

.boxinternal {
    display: flex;
    padding-inline: 26px;
    align-items: center;
    justify-content: center;
    gap: 12px;
}

@media only screen and (max-width: 600px) {

    .urlbox {
        max-width: 120px;
        text-align: center;
        align-items: center;
        font-size: 14px;
        font-weight: 600;
        background: linear-gradient(180deg, #FB8EB7, #EAA6C7, #D9BFD9);
        -webkit-background-clip: text; /* For Safari */
        background-clip: text;
        color: transparent; /* Make the text color transparent so the gradient shows */
    }
    

    .boxinternal {
        display: flex;
        padding-inline: 0px;
        justify-content: center;
        gap: 12px;
    }

    .withtransparency {

        position: relative;
        align-items: center;
        text-align: left;
      }
      .withtransparency:before {
        content:"";
        position: absolute;
        inset: 0;
        /* padding: 1px; */
        border: 1px solid #666;
        background: #666666;
        border-radius: 10px;
        --m:conic-gradient(#000 0 0);
        mask: var(--m) content-box exclude,var(--m);
        pointer-events: none;
      }
    .grid_heiliao_outside {
        display: block; /* Or flex, depending on your desired layout */
        padding: 1px;
        gap: 5px;
        max-width: 95%;
        margin-left: 10px;
    }
    .listinfo {
        display: grid;
        grid-template-columns: repeat(2, minmax(0, 1fr));

        /* place-items: center; */
        padding-bottom: 10px;
        /* padding: 0 10px; */
        padding: 0;
        font-size: 13px;
        /* width: 95%; */
        gap: 10px;
        width: 95%;
    }
    .list_fushou {
        display: grid;
        grid-template-columns: repeat(6, minmax(0, 1fr));
        place-items: center; /* Center items horizontally and vertically */
        padding: 0 10px
    }
    .grid_url {
        display: grid;
        grid-template-columns: repeat(4, minmax(0, 23%));
        gap: 5px;
        padding-left: 10px;
    }
    .home_direct_link{
        padding-left: 10px;
    }
    .home_icon{
        height: 15px;
        width: 15px;
    }
    .header_title{
        /* margin-left: 10px; */
    }
    .direct_link{
        font-size: 14px;
    }
    .main {
       display: none;
    }
    .ts {
        grid-template-columns: 1fr !important;
    }
    .ml250 {
        margin-left: 0px;
    }
    .main1 {
        display: grid;
        grid-template-columns: auto 1fr;
        gap: 10px !important;
        align-items: flex-start;
        padding: 20px;
        margin: 0px;
    }
    .ts_container {
        margin: 10px;
        gap: 20px;
    }
    .desc {
        height: 34px;
        overflow: hidden;
        text-overflow: ellipsis;
        margin-top: 0px;
    }
    .ts_btn {
        border-radius: 100px !important;
        width: 90px !important;
        height: 37px;
        font-size: 16px;
        font-weight: 700;
        margin-left: auto;
    }
    .ts_logo {
        width: 60px;
        height: 60px;
    }
    .ts_vid_container {
        position: fixed;
        bottom: 0px;
        z-index: 1001;
        align-items: center;
        width: 100%;
        margin: 0px;
        padding: 5px 0px 5px 0px;
    }
    .ts_vid_container {
        display: grid;
        grid-template-columns: auto 1fr auto;
    }
    .ts_vid_container>div:nth-child(1) {
        padding-left: 10px;
    }
    .ts_vid_container>div:nth-child(2) {
        text-align: center;
    }
    .ts_vid_container>div:nth-child(3) {
        padding-right: 10px;
    }
    .ts_sin_vid {
        font-weight: 700;
        font-size: 18px;
        text-align: center;
        display: block;
    }
}