.p_tye {
    border: 1px solid #dfdfdf;
    border-radius: 6px;
    height: 55px;        
    min-width: 100px;
    text-align: center;
    /* padding-top: 5px; */
    padding: 5px;
    
}

.slcted {
    border: 1px solid #dcb9d5;
}
.slc_color {
    color: #d2bda2 !important;

}
.item_p {
    color: #666;
    font-size: 12px;
}
.r0 {
    right: 0px;
    bottom: -5px;
}