.up {
    background: #cfcdec;
    color: #fff;
    padding: 10px;
    margin-top: 10px;
}
.n_3 {
    background: #FF647C;
    border-radius: 20px;
    color: #fff;
    margin: 0 auto;
    padding: 8px 0;
    text-align: center;
    width: 45%;
    margin-top: 10px;
    cursor: pointer;
}
.wrapper{
    display: grid;
    grid-template-columns: 1fr auto;
    background-color: white;
    padding: 20px;
    border-bottom: 1px solid #dcdcdc;
    align-items: center;
    cursor: pointer;
}
.contentwrapper{
    display: flex;
    /* align-items: center; */
    gap: 10px;
}
.content{
    display: flex;
    flex-direction: column;
    gap:5px;
}
.title{
    font-size: 18px;
    font-weight: bold;
}
.img{
    width: 60px;
    height: 60px;
}
.time{
    margin-left: auto;
    font-size: 14px;
    color: #808080;
}
.desc{
    overflow: hidden;
    height: 18px;
    text-overflow: ellipsis;
    white-space: nowrap;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;
}
.msgline{
    display: flex;
}
.redeemed {
    background: linear-gradient(90deg, #ebf4f5, #b5c6e0);
    margin-left: 5px;
    font-size: 12px;
    display: flex;
    align-items: center;
    padding-block: 3px;
    padding-inline: 10px;
    border-radius: 10px;
}
@media only screen and (max-width: 600px) {
    .redeemed {
        background: linear-gradient(90deg, #ebf4f5, #b5c6e0);
        padding-left: 0;
        font-size: 12px;
        display: flex;
        align-items: center;
        width: 30%;
        padding-block: 3px;
        padding-inline: 10px;
        border-radius: 10px;
    }
    .msgline{
        display: block;
    }
    .desc{
        height: 15px;
    }
    .title{
        font-size: 15px;
    }
    .img{
        width: 50px;
        height: 50px;
    }
}