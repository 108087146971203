.footer {
    background: linear-gradient(90deg, #FB8EB7, #EAA6C7, #D9BFD9);
    color: #fff;
    line-height: 22px;
    margin-bottom: 0;
    padding: 20px 10%;
}
.time {
    background: linear-gradient(90deg, #FB8EB7, #EAA6C7, #D9BFD9);
    color: white;
    padding-top: 15px;
    padding-bottom: 15px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 20px;
    border-top: 1px solid #fff;
}
.timea {
    text-decoration: none;
    color: white;
}
.time:visited {
    color: white;
}
.inner {
    display: flex;
    justify-content: center;
    gap: 60px;
    margin-top: 15px;
}
.title {
    color: #ffffff;
    font-weight: 700;
    font-size: 16px;
    margin-bottom: 7px;
}
.child {
    color: #ffffff;
    font-weight: 400;
    font-size: 12px;
    cursor: pointer;
}
.time_mobile {
    display: none;
}
.inner_mobile {
    display: none;
}

@media only screen and (max-width: 600px) {
    .inner {
        display: none;
    }
    .time {
        display: none;
    }
    .time_mobile {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 6px;
    }
    .inner_mobile {
        display: block;
    }
    .inner_mobile>div {
        border-top: 0.5px solid #ffffff;
        border-bottom: 0.5px solid #ffffff;
        padding-left: 10px;
        padding-right: 10px;
        padding-bottom: 10px;
    }
    .title {
        margin-top: 10px;
    }
}