/* Overlay for the dialog */
.dialogOverlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent background */
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000; /* Ensure it appears above other content */
    animation: fadeIn 0.3s ease-in-out;
  }
  
  /* Dialog container */
  .dialogContent {
    background: linear-gradient(135deg, #f8f8f8, #ff3636); /* Gradient background */
    padding: 30px;
    border-radius: 12px;
    width: 350px;
    text-align: center;
    box-shadow: 0 8px 15px rgba(0, 0, 0, 0.1);
    position: relative;
    animation: slideUp 0.3s ease-out;
  }
  
  /* Close button */
  .closeBtn {
    position: absolute;
    top: 15px;
    right: 15px;
    cursor: pointer;
    font-size: 20px;
    font-weight: bold;
    color: #888;
    transition: color 0.2s;
  }
  
  .closeBtn:hover {
    color: #333;
  }
  
  /* QR code container */
  .qrCodeContainer img {
    border-radius: 10px;
    margin-bottom: 20px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }
  
  /* Text Styling */
  .dialogContent div {
    font-size: 16px;
    color: #333;
    font-weight: 500;
    margin-bottom: 20px;
  }
  
  /* Download Button */
  .downloadBtn {
    background-color: #4CAF50; /* Green background */
    color: white;
    border: none;
    padding: 10px 20px;
    border-radius: 8px;
    cursor: pointer;
    font-size: 16px;
    transition: background-color 0.3s;
  }
  
  .downloadBtn:hover {
    background-color: #45a049;
  }
  
  .downloadBtn:focus {
    outline: none;
  }

  .topdesc {
    color: #FF8E9D;
    padding-top: 10px;
    font-size: 14px;
  }
  .title {
    color: #666666;
    padding-top: 10px;
    font-size: 30px;
    font-weight: 600;
  }
  .desctitle {
    font-weight: 500;
    font-size: 16px;
    color: #666666;
  }
  .descurl {
    font-weight: 400;
    font-size: 16px;
    color: #FF8E9D;
  }

  .pt20 {
    padding-top: 20px;
    line-height: 40px;
  }

  .warning {
    color: #FE0803;
    font-size: 14px;
    font-weight: 400;
    padding-top: 40px;
  }

  .btnsave {
    background: linear-gradient(90deg, #FB8EB7 10%, #EAA6C7 50%, #D9BFD9 100%);
    color: white;
    border-radius: 18px;
    width: 90%;
    padding-block: 12px;
    margin: auto;
    cursor: pointer;
    max-width: 330px;
    font-size: 18px;
    border: 0;
}

.logo {
  position: absolute;
  top: 5%;
  left: 25%;
  transform: translate(-50%, -50%);
}

.btnclose {
  position: absolute;
  top: 5%;
  right: 0;
  transform: translate(-50%, -50%);
}

.bgwhite {
  position: absolute;
  top: 54%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: #fff;
  width: 310px;
  height: 520px;
  border-radius: 20px;
}

.qrinternal {
  position: relative;
  padding-top: 20px;
}

.qrcodeinternal {
  position: absolute;
  top: 53%;
  left: 50%;
  transform: translate(-50%, -50%);
}
  
  
  /* Fade-in animation for the overlay */
  @keyframes fadeIn {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
  
  /* Slide-up animation for the dialog */
  @keyframes slideUp {
    from {
      transform: translateY(50px);
      opacity: 0;
    }
    to {
      transform: translateY(0);
      opacity: 1;
    }
  }

  @media only screen and (max-width: 600px) {

  }
  