.layout {
    grid-template-rows: auto 1fr auto;
}
.h_slide {
    height: 312px;
}
.category {
    grid-template-columns: 1fr auto;
}


@media only screen and (max-width: 1000px) {
   .layout {
        grid-template-columns: 1fr;
   }
    .category>div:nth-child(2) {
        display: none;
    }
    .category {
        grid-template-columns: 1fr;
        grid-template-rows: 1fr auto !important;
    }
    /* .site_cli>div:nth-child(2) {
        display: block;
    }
    .site_cli>div:nth-child(2)>div {
        display: grid;
        grid-template-columns: 1fr 1fr;
    }
    .site_cli>div:nth-child(2)>div img {
        width: 100%;
        height: 140px;
    } */
}

@media only screen and (max-width: 500px) {
    .layout {
         font-size: 13px;
    }
 }
