.inner_content {
    background: #fef7f1;
    border-radius: 10px;
    margin: 15px;
    padding: 15px;
    width: 100%;
    margin: auto;
}
.img_item {
    width: 100%;
    margin: 0 auto;
}
.img_item > img {
    border: 1px solid #b0946e;
    border-radius: 50px;
}
.img_text {
    color: #9d8b70;
    text-align: center;
    font-weight: bold;
}
.original_price {
    color: #888;
    margin-left: 15px;
    text-decoration: line-through;
}
.new_price {
    background: #eda64e;
    border-radius: 20px;
    color: #fff;
    display: inline-block;
    line-height: 25px;
    padding: 0 30px 0 10px;
    text-align: center;
}
.buy_btn {
    background: #5e4e3e;
    border-radius: 20px;
    float: right;
    line-height: 25px;
    padding: 0 10px;
    color: #fff;
    position: absolute;
    top: 0;
    right: -60px;
    cursor: pointer;
}

.name {
    color: #333;
    font-size: 18px;
    font-weight: 700;
}
.container {
    grid-gap: 120px;
    gap: 120px;
    position: absolute;
    width: 800px;
    top: 0px;;
    left: 50%;
    transform: translate(-52%, 0%);
    padding: 20px;
}
.left {
    color: #000;
    font-size: 15px;
}
.right {
    color: #666;
    font-size: 14px;
}
.item_btm {
    border-bottom: 1px solid #faf0f0;
    padding-bottom: 15px;
}
.btm_container {
    border-radius: 5px;
    padding: 15px;
    width: 100%;
    margin: auto;
    background-color: white;
}
.w902 {
    width: 902px;
    margin: auto;
}
.mt120 {
    margin-top: 120px;
}
.mt40 {
    margin-top: 40px;
}
.msgamt{
    align-items: center;
    width: 20px;
    height: 20px;
    border-radius: 20px;
    background-color: red;
    color: white;
    display: flex;
    padding: 1px;
    text-align: center;
    justify-content: center;
}
.vip_level_2 {
    color: #90684c;
    font-size: 16px;
    font-weight: 700;
    text-align: center;
}
.benefits {
    color: #b0946e;
    font-size: 12px;
    margin-bottom: 15px;
    margin-top: 7px;
    text-align: center;
}
.yongjiu {
    width: 30px;
    height: 34px;
    position: absolute;
    top: -14px;
    right: 0;
}
@media only screen and (max-width: 300px) {
    .img_p {
        grid-template-columns: 1fr 1fr 1fr !important;
        display: grid !important;
    }
    .inner_content > div:nth-child(1) {
        flex-direction: column;
        gap: 10px !important;
    }
    .inner_content > div:nth-child(1)>div:nth-child(2) {
        flex-direction: column;
        width: 100%;
    }
    .inner_content > div:nth-child(1)>div:nth-child(2)>div:nth-child(2) {
        transform: translate(-22px, 0px);
    }
}
@media only screen and (max-width: 600px) {
    .container {
        grid-gap: 120px;
        gap: 120px;
        position: absolute;
        width: 100%;
        top: 0px;;
        left: 50%;
        transform: translate(-50%, 0%);
        padding: 0px;
        margin-top: 40px;
    }
    .w902  {
        width: 100%;
    }
    .w902 > img:nth-child(1) {
        width: 100%;
        height: 280px !important;
    }
    .inner_content {
        padding-top: 10px;
        padding-bottom: 10px;
        width: 97%;
        padding-left: 0px;
        padding-right: 0px;
        gap: 10px;
    }
    .inner_content > div:nth-child(1) {
        display: flex;
        justify-content: center;
        gap: 0px;
    }
    .inner_content > div:nth-child(2) {
        gap: 10px;
    }
    .btm_container {
        padding: 0px;
    }
    .img_item {
        max-width: 40px;
        max-height: 40px;
    }
    .img_item > img {
        max-width: 40px;
        max-height: 40px;
    }
    .img_text {
        font-size: 12px;
    }
    .mt120 {
        margin-top: 0px;
    }
    .btm_container {
        width: 80%;
        padding: 20px;
    }
    .new_price {
        padding: 0 11px 0 6px;
    }
    .mt40 {
        margin-top: 20px;
        margin-bottom: 20px;
    }
    .benefits {
        margin-bottom: 0px;
    }
    .yongjiu {
        top: -10px;
    }
    .original_price {
        margin-left: 5px;
    }
    .new_price {
        font-size: 5px !important;
    }
}

@media only screen and (max-width: 400px) {
    .inner_content > div:nth-child(1) {
        flex-direction: column;
        gap: 10px;
    }
    .original_price {
        margin-left: -32px;
    }
}