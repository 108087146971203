.d {
    display: grid;
    gap: 10px;
    grid-template-columns: 1fr 314px;
    border: 1px solid #dadada;
    border-bottom: 0;
    box-shadow: 0 0 9px #dacdcd;
    padding: 10px;
}
.d>img {
    width: 98%;
}
.cv {
    color: #000;
    font-weight: bold;
}
.d_z {
    display: grid;
    grid-template-columns: 1fr 155px 155px;   
}   
.t {
    color: #444;
    display: block;
    font-size: 20px;
    font-weight: 700;
    line-height: 46px;
}
.f16 {
    font-size: 16px;
}
.f14 {
    font-size: 14px;
}
.f18 {
    font-size: 18px;
}

.t_b {
    border-bottom: 1px solid #dadada;
}

.al {
    color: #4a74ad;
    text-decoration: none;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 300px;
    display: inline-block;
    overflow: hidden;
}
.al:visited {
    color: #4a74ad;
    text-decoration: none;
}
.hd {
    background: #4a74ad;
    border-radius: 5px;
    color: #fff;
    font-size: 12px;
    margin-left: 10px;
    padding: 3px 7px;
    cursor: pointer;
}
.cp {
    background: #d7d7d7;
    border: 0;
    border-radius: 20px;
    color: #383838;
    height: 18px;
    line-height: 19px;
    padding: 3px 10px;
    font-size: 14px;
    cursor: pointer;
    margin-left: 10px;
}
.z300 {
    z-index: 300;
}
.ys_img {
    color: transparent;
    height: 54%;
    position: absolute;
    background-position: 50%;
    background-size: cover;
    filter: blur(40px);
    left: 50%;
    transform: translate(-50%, 10px);
}
.xs_detail {
    background-color: #fff;
    border-radius: 15px;
    min-height: 600px;
    padding: 50px 40px;
    text-align: center;
    display: grid;
    gap: 10px;
    position: relative;
    top: -71px;
}
.xs_title {
    color: #6b6b6b;
    font-size: 18px;
    font-weight: 700;
    margin-bottom: 10px;
    text-align: center;
}
.xs_content {
    color: #7b7b7b;
    font-size: 16px;
}
.text_l {
    text-align: left;
    margin-top: 70px;
}

.news_t {
    font-size: 18px;
    font-weight: 700;
    margin-top: 20px;
}
.ap_dwn {
    background-color: #0024ff;
    border-radius: 32px;
    height: 32px;
    line-height: 32px;
    text-align: center;
    width: 80px;
    color: white;
}
.ap_img {
    display: flex;
    gap: 10px;
}


.mvd_tip {
    background-color: #fff;
    border-radius: 15px;
    padding: 10px 15px;
    text-align: center;
    margin-bottom: 20px;
}
.news_img img {
    width: 100% !important;
    height: 100% !important;
}

@media only screen and (max-width: 600px) {
    .d {
        grid-template-columns: 1fr;
    }
    .d_z {
        grid-template-columns: 1fr auto auto;
        padding: 10px;
        gap: 10px;
    }
    .d_z > div:nth-child(1)>div:nth-child(1) {
        flex-direction: column;
    }
    .cs {
        height: 20px;
        line-height: 20px;
    }
    .al {
        width: 150px;
        overflow: hidden;
    }
    .hd {
        margin-left: 0px;
    }
    .w30 {
        padding-left: 10px;
        padding-right: 10px;
    }
    .news_t {
        padding-left: 5px;
        padding-right: 5px;
    }
    .news_img {
        padding-left: 5px;
        padding-right: 5px;
    }
}

