.datalist {
    width: 98%;
    border: solid 1px #d1d1d1;
    height: 25px;
    /* margin-bottom: 10px; */
}

.inner {
    display: grid;
    grid-template-columns: 1fr 1fr;
}

.buttonsize {
    width: 50%;
}

@media only screen and (max-width: 600px) {
.buttonsize {
    width: 70%;
}
}
