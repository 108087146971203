.content {
    position: absolute;
    width: 100%;
}
.redexpire {
    color: red;
    font-weight: 700;
}
.mt110 {
    margin-top: 110px;
}
.btm {
    margin-top: 18px;
    margin-bottom: 10px;
    display: flex;
    flex-flow: column;
    gap: 15px;
}
.titleMd{
    padding-top: 10px;
    padding-bottom: 10px;
    border-top-right-radius: 5px;
    border-top-left-radius: 5px;
    background: #f4adc5;
    font-weight: 700;
}
.gy {
    width: 80%;
    background-color: #fff;
    border: 1px solid gray;
    border-radius: 5px;
    height: 40px;
    margin: 0 0 0 8px;
    outline: 0;
    min-width: auto !important;
    /* overflow: hidden; */
    line-height: 33px;
    overflow-x: scroll;
    padding-left: 10px;
    padding-right: 10px;
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
    text-align: left;
}
.gy::-webkit-scrollbar {
    display: none;
}
@media only screen and (max-width: 600px) {
    .btm {
        padding: 5px;
    }
}