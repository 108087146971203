.container {
    width: 100%;
    min-height: 100vh;
    background-image: url('/public/assets/images/h5/bg.png');
    background-repeat: no-repeat;
    position: relative;
    color: #F1F1F1;
  }
  .container .top_container {
    width: 80%;
    position: relative;
    padding-inline: 40px;
  }
  .container .top_container .img_logo {
    position: absolute;
    top: 20px;
    left: 40px;
    width: 34vw;
    z-index: 2;
  }
  .container .top_container .btn_download {
    position: absolute;
    top: 20px;
    right: 40px;
    width: 20vw;
    z-index: 2;
  }
  .container .top_container .img {
    width: 100%;
    height: auto;
  }
  .container .center_container {

    padding: 0 40px;
  }
  .gridcon {
    display: flex;
    gap: 10px;
    justify-content: center;
    
}
  .container .center_container .center_top {
    display: flex;
    justify-content: space-between;
  }
  .container .center_container .center_top .center_top_item .icon {
    width: 26vw;
    height: 16vw;
  }
  .container .center_container .center_content {
    margin-top: 10px;
  }
  .container .center_container .center_content .center_item {
    display: flex;
    border: 1px solid #F1F1F1;
    border-radius: 4px;
    padding: 4px 8px 4px 20px;
    align-items: center;
    margin-top: 10px;
  }

.center_item {
    display: flex;
    border: 1px solid #F1F1F1;
    padding: 4px 8px 4px 20px;
    border-radius: 4px;
    align-items: center;
    margin-top: 10px;
  }
  .container .center_container .center_content .center_item:nth_child(1) {
    margin-top: 0;
  }
  .container .center_container .center_content .center_item .icon {
    width: 10vw;
    height: 10vw;
  }
  .center_text {
    margin-left: 10px;
  }
.center_text_top {
    font-size: 14px;
  }
  .center_text_bottom {
    font-size: 12px;
    margin-top: 6px;
  }
  .container .center_container .center_content .center_item .center_text .center_text-bottom {
    font-size: 12px;
    margin-top: 6px;
  }
 .btn_right {
    margin-left: auto;
    align-self: center;
    background-color: #E84F66;
    font-size: 14px;
    width: 90px;
    line-height: 30px;
    border-radius: 15px;
    text-align: center;
    color: #fff;
  }
.footer {
    margin-top: 20px;
    padding: 0 40px 60px 40px;
  }
.footer_item {
    margin-bottom: 15px;
    width: 100%;
  }
  .container .footer .footer_item img {
    width: 100%;
  }
  .swiper_wrapper {
    padding-bottom: 40px;
  }

.paginationContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  margin-top: 15px; /* Space between slider and pagination */
}

.paginationDot {
  width: 20px;
  height: 12px;
  border-radius: 6px;
  background: grey;
  cursor: pointer;
  transition: background 0.3s, transform 0.3s;
}

.paginationDot:hover {
  background: lightgrey; /* Hover effect */
}

.activeDot {
  background: white; /* Active dot color */
  width: 40px;
  transform: scale(1.2); /* Slightly enlarge the active dot */
}
  