.ts {
    margin-top: 20px !important;
    grid-template-columns: 1fr;
}
.ts_logo {
    width: 80px;
    height: 80px;
    border-radius: 50px;
}
.ts_title {
    color: #242424;
    font-size: 18px;
    font-weight: 700;
    /* padding: 10px !important; */
    grid-column: span 1 / span 1;
    padding-top: 100px;
}
.line {
    height: 1px;
    width: 100%;
    background: #E7E7E7;
}
.gif_wrapper{
    display: grid;
    grid-template-columns: repeat(4, minmax(0, 1fr));
    gap: 5px;
}

.gif_images{
    height: auto;
}
.centered_gif_wrapper{
    background-color: rgba(0, 0, 0, 0.8); /* Adjust the alpha value (0.5) for transparency */
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 999; /* Place it behind other content */
}
.centered_gif{
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 999;
}
.centered_gif > img {
    max-width: 600px;
    width: 600px;
    max-height: 600px;
}
.ts_title_top {
    /* display: grid; */
    align-items: center;
    /* grid-template-columns: repeat(2, minmax(0, 1fr)); */
    /* gap: 30px; */
    position: relative;
    background: linear-gradient(90deg, #FB8EB7, #EAA6C7, #D9BFD9);
    color: white;

}

.ts_container {
    border: 1px solid #ECECEC;
    border-radius: 0 0 10px 10px;
    box-shadow: 
    -2px 0px 4px rgba(0, 0, 0, 0.2), /* Left shadow */
    2px 0px 4px rgba(0, 0, 0, 0.2),  /* Right shadow */
    0px 4px 4px -2px rgba(0, 0, 0, 0.2); /* Bottom shadow */    
    background-color: white;
    align-items: center;
    /* position: absolute; */
    color: white; /* Text color */
    text-align: center; /* Centers the text */
    margin: auto;
    justify-content: center; 
}

.ts_container_mobile {
    border: 1px solid #ECECEC;
    background-color: white;
    align-items: center;
    position: absolute;
    bottom: 1px; /* Aligns the text container at the bottom of the image */
    transform: translate(6%, 50%); /* Adjust by 50% of the element's own height and width to truly center it */
    width: 89%; /* Makes the container span half of the image width */
    color: white; /* Text color */
    text-align: center; /* Centers the text */
    margin: auto;
    justify-content: center; 
    border-radius: 10px;
}

.ts_container_tab {
    /* padding: 10px; */
    display: flex;
    flex-direction: column;
}
.main2 {
    display: flex;
    flex-direction: column;
}

/* Tab navigation container */
.tabContainer {
    display: flex;
    /* justify-content: space-around; */
    padding: 10px 0;
    border-bottom: 1px solid #ddd;
    text-align: left;
}

/* Tab buttons styling */
.tabContainer button {
    font-size: 16px;
    color: #666; /* Default color for unselected tabs */
    /* padding: 10px 20px; */
    background: none;
    border: none;
    outline: none;
    cursor: pointer;
    transition: color 0.3s ease;
    position: relative;
    font-weight: normal;
}

/* Add underline effect for the active tab */
.tabContainer button::after {
    content: '';
    position: absolute;
    height: 2px;
    background-color: #FF8E9D; /* Color of the underline */
    left: 50%;
    bottom: -5px;
    transition: width 0.3s ease, left 0.3s ease;
}

/* Active tab style */
.tabContainer button.activeTab {
    color: #FF8E9D; /* Pink color for active tab */
    font-weight: bold;
}

/* Active tab underline expands */
.tabContainer button.activeTab::after {
    width: 85%;
    left: 4px;
}

/* Hover effect to highlight tabs when hovering */
.tabContainer button:hover {
    color: #333;
}

.tabContainer button:hover::after {
    width: 85%;
    left: 4px;
}


.container {
    grid-gap: 120px;
    gap: 120px;
    position: absolute;
    width: 800px;
    top: 0px;;
    left: 50%;
    transform: translate(-52%, 0%);
    padding: 20px;
}

.importanttext{
    color: rgb(255, 52, 125);
    font-weight: normal;
    padding-top: 10px;
}
.desc {
    padding: 10px;
    font-size: 14px;
    font-weight: 400;
    color: #666666;
    /* padding: 20px; */
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 5;
    overflow: hidden;
    display: grid;
    grid-template-columns: 60px 1fr;
}
.left_cover {
    width: 100%;
    display: flex;
    gap: 10px;
    justify-content: end;
    text-align: right;
    padding-top: 10px;
}
.ml250 {
    margin-left: 250px;
}
.main1 {
    display: none;
}
.ts_detail {
    color: #fff;
    font-size: 12px;
    font-weight: 400;
}
.ts_de_img {
    border-radius: 50px;
}
.ts_sin_vid {
    display: none;
}
.currentprice{
    font-size: 14px;
    font-weight: 500;
}
.becomevip{
    border: 1px solid #FF347D;
    background: #FF347D26;
    color: #FF347D;
    padding: 4px 8px;
    border-radius: 50px;
    display: flex;
    justify-content: center;
    cursor: pointer;
}
.pricetag{
    display: grid;
}
.pricetagmobile{
    display: none;
}
.buttontag{
    width: 100%;
    display: grid;
    grid-template-columns: 25% 72%;
    gap: 10px;
    text-align: end;
    padding-top: 10px;
}
.gif_title{
    grid-template-columns: repeat(4, minmax(0, 1fr)) !important;
    font-size: 16px;
}
.header_title{
    display: flex;
    font-size: 21px;
    margin-bottom: 2px !important;
}
.direct_link{
    cursor: pointer;
    font-weight: 600;
    color: rgb(231, 121, 150);
    display: flex;
    justify-content: center;
    gap: 2px;
    font-size: 18px;
}
.arrow{
    rotate: 180deg;
    width: 15px;
    filter: opacity(0.5);
}
.sub_link{
    color: #e77996;
    font-weight: 500;
}
@media only screen and (max-width: 600px) {
    .home_icon{
        height: 15px;
        width: 15px;
    }
    .home_direct_link{
        padding-left: 10px;
    }
    .direct_link{
        font-size: 14px;
    }
    .img_con {
        grid-template-columns: repeat(2, 1fr);
        padding-left: 10px;
        padding-right: 10px;
        background-color: #eeeeee;
    }
    .centered_gif > img {
        max-height: 350px;
        max-width: 350px;
    }
    .gif_title{
        grid-template-columns: repeat(2, minmax(0, 1fr)) !important;
    }
    .gif_wrapper{
        grid-template-columns: repeat(2, minmax(0, 1fr)) !important;
    }
    .ts_title_top{
        gap: 10px !important;
    }
    .pricetagmobile{
        display: flex;
        grid-column: span 2/ span 2;
    }
    .buttontag{
        width: 100%;
        display: block;
        gap: 10px;
        text-align: center;
        padding-top: 10px;
    }
    .pricetag{
        display: none !important;
    }
    .ts {
        grid-template-columns: 1fr !important;
    }
    .ml250 {
        margin-left: 0px;
    }
    .main1 {
        display: grid;
        grid-template-columns: auto 1fr;
        gap: 10px !important;
        align-items: flex-start;
        padding: 20px;
        margin: 0px;
    }
    .ts_container {
        border: 1px solid #b4b4b4;
        border-radius: 1px;
        box-shadow: 
        0px 0px 0px rgba(0, 0, 0, 0), /* Left shadow */
        0px 0px 0px rgba(0, 0, 0, 0),  /* Right shadow */
        0px 0px 0px -2px rgba(0, 0, 0, 0); /* Bottom shadow */    
        background-color: white;
        align-items: center;
        /* position: absolute; */
        color: white; /* Text color */
        text-align: center; /* Centers the text */
        margin: auto;
        justify-content: center; 
        background: #fafafa;
    }
    .left_cover {
        flex-direction: column;
    }
    .desc {
        margin-top: 0px;
        padding: 10px;
    }
    .ts_btn {
        border-radius: 100px !important;
        width: 90px !important;
        height: 37px;
        font-size: 16px;
        font-weight: 700;
        margin-left: auto;
    }
    .ts_logo {
        width: 60px;
        height: 60px;
    }
    .ts_vid_container {
        position: fixed;
        bottom: 0px;
        z-index: 1001;
        align-items: center;
        width: 100%;
        margin: 0px;
        padding: 5px 0px 5px 0px;
    }
    .ts_vid_container {
        display: grid;
        grid-template-columns: auto 1fr auto;
    }
    .ts_vid_container>div:nth-child(1) {
        padding-left: 10px;
    }
    .ts_vid_container>div:nth-child(2) {
        text-align: center;
    }
    .ts_vid_container>div:nth-child(3) {
        padding-right: 10px;
    }
    .ts_sin_vid {
        font-weight: 700;
        font-size: 18px;
        text-align: center;
        display: block;
    }
}