.up {
    background: #cfcdec;
    color: #fff;
    padding: 10px;
    margin-top: 10px;
}
.n_3 {
    background: #FF647C;
    border-radius: 20px;
    color: #fff;
    margin: 0 auto;
    padding: 8px 0;
    text-align: center;
    width: 45%;
    margin-top: 10px;
    cursor: pointer;
}
.billitem {
    display: grid;
    grid-template-columns: 1fr auto;
    background-color: white;
    padding: 20px;
    border-bottom: 1px solid #dcdcdc;
    align-items: center;
}
.billitem>div:nth-child(1) {
    font-size: 14px;
    color: #808080;
    display: grid;
    gap: 20px;
}
.billitem>div:nth-child(2) {
    font-size: 18px;
    color: #808080;
}
.topic {
    font-size: 15px;
    color: #808080;
}
.rightbar{
    display: flex;
    gap: 1em;
    font-size: 15px;
    color: #808080;
}
.rightbtn{
    cursor: pointer;
    font-size: 14px;
}
.external{
 font-size: 15px;
 color: #7783ea;
 text-decoration: none;
}
.zonghuanmawrapper{
    border: 1px solid #7783ea;
    border-radius: 4px;
    padding: 5px 1px;
    max-width: 30%;
}

@media only screen and (max-width: 600px) {
    .zonghuanmawrapper{
        max-width: 80%;
    }
}