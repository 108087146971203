.main > div:nth-child(1) {
    position: fixed;
    top: 100px;
    left: 0px;
    z-index: 999;
}
.main > div:nth-child(2) {
    position: fixed;
    top: 100px;
    right: 0px;
    z-index: 999;
}
.xmark {
    top: 0;
    width: 13px;
    height: 21px;
    background: black;
    color: white;
    /* padding: 2px; */
    text-align: center;
    position: absolute;
    right: 0px;
    cursor: pointer;
}
.fl_img {
    width: 130px;
    height: 220px;
    object-fit: contain;
}

@media only screen and (max-width: 600px) {
    .fl_img {
        width: 63px;
        height: 107px;
    }
}