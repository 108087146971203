.ch {
    margin: 0 auto;
    max-width: 1200px;
    min-width: 200px !important;
    width: 100%;
}
.banner{
    width: 100%;
}
.banner_def {
    min-height: 50px;
    margin-bottom: 28px;
}
.banner_vip_def {
    /* min-height: 200px */
    min-height: 60px;
}
.topic_banner{
    cursor: pointer;
}

@media only screen and (max-width: 500px) {
    .ch {
        width: 100% !important;
        min-width: 100% !important;
        max-width: 100% !important;
        margin: 0 auto;
        margin-top: 52px !important;
    }
    .banner_def {
        min-height: 0px;
        margin-bottom: 0px;
    }
    .site_cili_img {
        display: none !important;
     }
}
.site_cili_img {
    height: 120px;
    width: 174px;
    cursor: pointer;
 }