.ts_con {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 15px;
    margin-bottom: 20px;
}
.img_con {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 15px;
    margin-bottom: 20px;
    padding-top: 20px;

}
.freeList {
    grid-template-columns: repeat(3, 1fr); /* 2 columns for free list */
}

.paidList {
    grid-template-columns: repeat(3, 1fr); /* 3 columns for paid list */
}
.xs_con {
    display: grid;
    grid-template-columns: 1fr 1fr;
    background-color: #fff;
}
.ys_con  {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
}

.ys_con > div > div > div {
    display: flex;
    align-items: center;
    gap: 20px;
}

.xs_con > div {
    padding: 10px;

}
.xs_con > div:hover {
    padding: 10px;

}
.xs_con > div > div > div {
    display: grid;
    gap: 10px;
    padding: 10px;
}
.xs_con>div:hover {
    color: #e77996 !important;
    cursor: pointer;
}
.xs_title {
    font-size: large;
    font-weight: 700;
}
.xs_t {
    font-size: 12px;
    font-weight: 400;
}
.xs_d {
    /* color: #6A6A6A; */
    font-size: 16px;
    line-height: 30px;
    height: 64px;
    overflow: hidden;
    text-overflow: ellipsis;
}

.xs_b {
    /* color: #333333; */
    font-size: 12px;
}

.ys_t {
    color: #000;
    font-size: 18px;
    font-weight: 700;
}
.ys_g {
    color: #666;
    font-size: 13px;
    margin: 10px 0;
}
.ys_sm {
    color: #e797a5;
    font-size: 13px;
}
.ys_b {
    color: #888;
    font-size: 12px;
}
.ys_main {
    cursor: pointer;
}
.yw_main {
    display: grid;
    gap: 10px;
}
.list_item {
    height: 100%; 
    display: flex;
    flex-direction: column;
    justify-content: space-between; /* Pushes title and description apart */
}

.videoContainer {
    display: flex;
    flex-direction: column;
    justify-content: flex-start; /* Align items to the top */
    /* min-height: 300px; */
    border: 2px solid #ccc;
    box-sizing: border-box;
}

.description {
    flex-grow: 1; /* Allows the description to take up remaining space */
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    line-clamp: 3; /* Fallback for other browsers */
    box-sizing: border-box;
    padding-block: 5px; /* Add padding for spacing */
}

/* Title styles */
.title {
    font-size: 14px;
    font-weight: 600;
    padding: 10px 5px 5px 5px;
    height: 28px; /* Adjust based on line height and number of rows */
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    white-space: normal; /* Ensures text wraps into multiple lines */
}

/* Expand button */
.showMoreButton {
    text-align: right;
    font-size: 14px;
    color: #ff7f7f;
    background: none;
    border: none;
    cursor: pointer;
    margin-top: -10px;
    align-self: flex-end; /* Ensures the button aligns at the bottom of the flex container */
}

.xs_list_item {
    display: grid;
    grid-template-rows: 1fr 1fr;
}
.list_item>div:nth-child(2) {
    color: #333;
    font-size: 14px;
    /* font-weight: 600; */
}
.shadow {
    position: absolute;
    top: 0;
    height: 100%;
    width: 100%;
    background: linear-gradient(transparent,rgba(0,0,0,.5));
    color: #fff;
    filter: alpha(opacity=0);
    cursor: pointer;
    transition: opacity .3s;
}
.shadow>*:nth-child(1) {
    position: absolute;
    left: 50%;
    transform: translate(-50%, -55%);
    top: 50%;
}
.shadow>*:nth-child(2) {
    position: absolute;
    bottom: 0px;
    width: 100%;
    text-align: right;
    background-image: linear-gradient(transparent,rgba(0,0,0,.5));
}
.shadow>*:nth-child(2)>div:nth-child(1) {
    padding-right: 5px;
    padding-bottom: 5px;
}
.vipmark {
    background: #dab572;
    border-radius: 3px;
    font-size: 12px;
    font-weight: 700;
    padding: 3px 5px;
    position: absolute;
    right: 0;
    top: 5px;
    z-index: 99;
}
.vipmark:hover {
    color: #FF8E9D;
}
.vid_duration {
    position: absolute;
    bottom: 3px;
    padding: 2px 5px;
    left: 2px;
    color: white;
    background: rgb(31 41 55 / .75);
    width: max-content;
    float: left;
    text-align: left;
    border-radius: 4px;
    font-size: 13px;
}
.vid_time {
    font-size: 12px;
    position: absolute;
    bottom: 0px;
    color: white;
    background-image: linear-gradient(transparent,rgba(0,0,0,.5));
    width: 100%;
    float: right;
    text-align: right;
}
.vid_time>div:nth-child(1) {
    padding-right: 5px;
    padding-bottom: 4px;
    font-weight: 500;
}
.tag {
    position: absolute;
    top: 5px;
    right: 5px;
    /* bottom: 5px; */
    background: #FF8E9D;
    padding: 2px 10px;
    border-radius: 50px;
    color: white;
    font-size: 10px;
    font-weight: 700;
    z-index: 50;
}

.imageContainer {
    width: 100%;
    /* height: 300px; */
    object-fit: cover;
}

@media only screen and (max-width: 600px) {
    .img_con {
        grid-template-columns: 1fr; /* 1 column for mobile */
    }

    .img_con > :nth-child(1) {
        grid-column: 1 / -1; /* Span full width for the first item */
        overflow: hidden; /* To handle any overflow */
    }

    .img_con > :nth-child(1) .imageContainer {
        height: 200px; /* Larger height for the first video */
    }

    .img_con > :nth-child(n + 2) {
        grid-template-columns: repeat(2, 1fr); /* Each subsequent video in two columns */
    }

    .img_con > :nth-child(n + 2) .imageContainer {
        height: 120px; /* 100px height for the rest of the images */
    }

    .img_con > :nth-child(n + 2) {
        grid-column: span 1; /* Each subsequent item spans 1 column */
    }

    /* Adjust spacing if needed */
    .img_con {
        grid-gap: 10px; /* Optional: adjust the gap for mobile */
    }
    .imageContainer {
        width: 100%;
        object-fit: cover;
    }
    .img_con {
        grid-template-columns: repeat(2, 1fr);
        padding-left: 10px;
        padding-right: 10px;
        background-color: #eeeeee;

    }
    .ts_con {
        grid-template-columns: repeat(2, 1fr);
        padding-left: 10px;
        padding-right: 10px;
    }
    .img_con img{
        object-fit: contain;
    }
    .xs_con {
        display: grid;
        grid-template-columns: 1fr;
        background-color: #fff;
    }
    .ys_con  {
        display: grid;
        grid-template-columns: 1fr;
        padding-left: 10px;
        padding-right: 10px;
    }
}
