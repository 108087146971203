.selected {
    color: #e77996;
}

.unselected {
    color: #717171
}

.inner3 {
    grid-template-rows: auto auto auto;
    width: 380px;
    padding-top: 40px;
}
.forgot {
    height: 20px;
    line-height: 20px;
    text-align: right;
    color: #3046ec;
    cursor: pointer;
}
.btm {
    color: #8f8f8f;
    font-size: 16px;
    text-align: center;
}
.form {
    background: #fff;
    border-radius: 3px;
    height: 515px;
    width: 500px;
    font-size: 20px;
    line-height: 80px;
    grid-template-rows: 80px 1fr;
    font-size: 16px;
    align-items: end;
}
.phone_select {
    max-height: 51px;
    display: flex;
    gap: 10px;
    align-items: center;
}

@media only screen and (max-width: 600px) {
    .inner3 {
        width: 100% !important;
    }
}