.p {
    display: flex;
    background: #fff;
    border-radius: 15px;
    padding: 20px 0;
    justify-content: center;
    margin-bottom: 20px;
}
.p > div {
    flex: 1;
    text-align: center;
    display: grid;
    justify-content: center;
    gap: 10px;
}
.clr {
    color: #e77996;
    font-weight: 700;
}
.dls {
    font-weight: 700;
    color: black;
}

@media only screen and (max-width: 600px) {
    .p {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr 1fr;
        margin: 10px;
    }
}
