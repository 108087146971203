.f {
    background-color: #ededed;
    width: 100%;
    height: 100%;
}
.main {
    margin: 0 auto;
    max-width: 500px;
    min-height: 100vh;
    padding: 5% 2%;
    position: relative;
    text-align: center;
}
.btn {
    background-color: #5b86b3;
    color: #fff;
    flex: 1;
    line-height: 40px;
    margin: 0;
    padding: 0 10px;
    margin-top: 20px;
    margin-bottom: 20px;
    cursor: pointer;
}
.w {
    color: #000;
    font-size: 12px;
    font-weight: 500;
}
.h {
    font-size: 15px;
    font-weight: 700;
    line-height: 40px;
}
.lh25 {
    line-height: 25px;
}

.wb {
    background: #fff;
    color: #000;
    flex: 1;
    font-size: 14px;
    height: 40px;
    line-height: 40px;
    text-align: center;
    vertical-align: middle;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
    cursor: pointer;
}
.b {
    color: blue;
}
.bx {
    font-size: 12px;
    font-weight: 500;
    cursor: pointer;
}