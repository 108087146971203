.chap_img {
    grid-template-columns: 1fr 1fr;
    gap: 20px;
}
.rad10 {
    border-radius: 10px;
}
.t {
    color: #6b6b6b;
    font-size: 18px;
    font-weight: 700;
    margin-bottom: 10px;
    text-align: center;
}
.t_1 {
    color: #7B7B7B;
    font-size: 16px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    gap: 10px;
}

.ys_item {
    border-bottom: 1px solid #f1e8e8;
    cursor: pointer;
    display: flex;
    overflow: hidden;
    padding: 5px;
    width: 100%;
    height: 50px;
}
.ys_item>div:nth-child(1) {
    padding-left: 20px;
    display: flex;
    align-items: center;
}
.ys_item>div:hover {
    color: #e77996;
}
.tt {
    font-size: 18px;
    line-height: 35px;
}

.item {
    border: 2px solid #bebebe;
    border-radius: 10px;
    margin: 5px;
    min-width: 120px;
    padding: 10px;
    cursor: pointer;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
}
.mm {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
}

@media only screen and (max-width: 600px) {
    .chap_img {
        grid-template-columns: 1fr;
        gap: 20px;
        padding: 10px;
    }
}