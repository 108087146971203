.vid {
    width: 823px;
    position: relative;
    /* height: 100%; */
}
.img_p {
    display: flex;
    /* gap: 120px; */
    justify-content: space-between;
    padding-inline: 30px;
    margin-top: 40px;

}
.imgdetails {
    text-align: center;
}
.fontdet {
    color: #FF8E9D;
    padding-top: 10px;
}
.tag_sty{
    width: 100%;
    justify-content: end; 
}
.tag {
    position: absolute;
    top: 5px;
    left: 5px;
    /* bottom: 5px; */
    background: #FA528E;
    padding: 2px 10px;
    border-radius: 50px;
    color: white;
    font-size: 10px;
    font-weight: 700;
    z-index: 50;
}
.titletag{
    grid-template-columns: auto 1fr;
}
.route_sty {
    /* background: #d7d7d7; */
    border-radius: 5px;
    /* color: #333; */
    line-height: 35px;
    text-align: center;
    /* width: 90px; */

    width: fit-content;
    padding: 0 10px;
    /* margin-left: auto; */
    background: rgb(231, 121, 150);
    /* font-weight: 600; */
    font-size: 12px;
    color: #e8e3e3;
}
.route_sty2 {
    /* background: #d7d7d7; */
    /* border-radius: 5px; */
    /* color: #333; */
    line-height: 35px;
    /* text-align: center; */
    /* width: 90px; */

    width: fit-content;
    padding: 0 10px;
    /* margin-left: auto; */
    /* background: rgb(231, 121, 150); */
    /* font-weight: 600; */
    font-size: 16px;
    color: #707070;
}
.route_text_sty{
    color: #707070;
}
.vidDescription{
    /* font-weight: 700; */
}
.vidc {
    display: grid !important;
    grid-template-columns: 1fr 276px !important;
    /* grid-template-columns: 823px 1fr !important; */
    row-gap: 5px;
    column-gap: 30px;
}
.dwn_btn {
    background: #49a0fe;
    border-radius: 5px;
    color: #fff;
    display: inline-block;
    height: 35px;
    width: 100px;
    display: flex;
    align-items: center;
    justify-content: center;
}
.l {
    border-bottom: 2px solid #ccc;
}
.gy {
    /* background-color: #ccc; */
    border: 1px solid #A9A9A9;
    border-radius: 15px;
    height: 40px;
    /* margin: 0 0 0 8px; */
    outline: 0;
    max-width: 650px;
    min-width: 500px !important;
    /* overflow: hidden; */
    line-height: 33px;
    overflow-x: scroll;
    padding-left: 10px;
    padding-right: 10px;
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
}
.gy::-webkit-scrollbar {
    display: none;
}

.vid_vip {
    background: linear-gradient(180deg,#ffefb8,#d3be91);
    border-radius: 20px;
    justify-content: center;
    line-height: 45px;
    margin: 10px 0;
    padding: 0 20px;
    text-align: center;
    /* width: 55%; */
    display: flex;
    align-items: center;
    gap: 10px;
    margin: auto;
    cursor: pointer;
}

.vid_share {
    background: transparent;
    border: 1px solid #d1d1d1;
    border-radius: 30px;
    min-width: 310px;
    width: 100%;
    display: grid;
    grid-template-columns: auto auto 1fr auto;
    grid-gap: 10px;
    gap: 10px;
    align-items: center;
    padding-left: 10px;
    margin: auto;
}

.copy {
    background: #d7d7d7;
    border: 0;
    border-radius: 30px;
    color: #383838;
    height: 36px;
    line-height: 36px;
    padding: 0 10px;
    cursor: pointer;
}

.vidbtm_item {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 12px;
    flex-direction: column;
    gap: 10px;
}
.override_vidc {
    grid-template-columns: 1fr !important;  
}
.override_vidc>div:nth-child(1) {
    width: 100% !important;
}
.override_vidc>div:nth-child(2) {
    display: none !important;
}
.rightvidtag{
    grid-template-columns: repeat(2, minmax(0, 1fr));
}

.datetext{
    color: #999999;
}
.centered_gif_wrapper{
    background-color: rgba(0, 0, 0, 0.8); /* Adjust the alpha value (0.5) for transparency */
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 999; /* Place it behind other content */
}
.centered_gif{
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 999;
}
.centered_gif > img {
    max-width: 600px;
    width: 600px;
    max-height: 600px;
}
.tabwrapper{
    display: flex;
    gap: 25px;
    margin-top: 10px;
    border-bottom: 1px solid #CCCCCC;
    padding: 0 10px;
}
.tab{
    display: flex;
    gap: 5px;
    padding: 10px 0;
    align-items: center;
    cursor: pointer;
}
.sub_title{
    color: rgb(231, 121, 150);
    font-weight: 600;
}
.tabselected{
    border-bottom: 3px solid #FF3F88;
    color: #FF3F88;
}
.thumb_img_series_wrapper{
    display: grid;
    grid-template-columns: repeat(8, minmax(0, 1fr));
    gap: 15px;
}
.thumb_series_wrapper{
    display: grid;
    grid-template-columns: repeat(4, minmax(0, 1fr));
    gap: 15px;
}
.thumb_series_wrapper_2{
    display: grid;
    grid-template-columns: repeat(1, minmax(0, 1fr));
    gap: 15px;
}
.xs_title {
    font-size: large;
    font-weight: 700;
}
.xs_t {
    font-size: 12px;
    font-weight: 400;
}
.vid_duration {
    position: absolute;
    bottom: 3px;
    padding: 2px 5px;
    left: 2px;
    color: white;
    background: rgb(31 41 55 / .75);
    width: max-content;
    float: left;
    text-align: left;
    border-radius: 4px;
    font-size: 13px;
}
.vid_time {
    font-size: 12px;
    position: absolute;
    bottom: 0px;
    color: white;
    background-image: linear-gradient(transparent,rgba(0,0,0,.5));
    width: 100%;
    float: right;
    text-align: right;
}
.vid_time>div:nth-child(1) {
    padding-right: 5px;
    padding-bottom: 4px;
    font-weight: 500;
}
.vipmark {
    background: #dab572;
    border-radius: 3px;
    font-size: 12px;
    font-weight: 700;
    padding: 3px 5px;
    position: absolute;
    right: 0;
    top: 5px;
    z-index: 99;
}
.vipmark:hover {
    color: #FA528E;
}
.list_item {
    display: grid;
    grid-template-rows: 1fr 40px;
    /* grid-template-rows: 1fr 20px; */
    /* overflow: hidden; */
    /* text-overflow: ellipsis; */
}

.list_url {
    display: grid;
    grid-template-columns: repeat(6, minmax(0, 1fr));
    gap: 5px;
}
.list_fushou {
    display: grid;
    grid-template-columns: repeat(10, minmax(0, 1fr));
}
.grid_heiliao {
    display: grid;
    grid-template-columns: auto 1fr;
    gap: 5px;

}

.grid_announcementtitle {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr; /* Adjust min width as needed */
    /* place-items: center; */
    gap: 20px;

}
.xs_list_item {
    display: grid;
    grid-template-rows: 1fr 1fr;
}
.list_item>div:nth-child(2) {
    color: #333;
    font-size: 14px;
    /* font-weight: 600; */
}
.shadow {
    position: absolute;
    top: 0;
    height: 100%;
    width: 100%;
    background: linear-gradient(transparent,rgba(0,0,0,.5));
    color: #fff;
    filter: alpha(opacity=0);
    cursor: pointer;
    transition: opacity .3s;
}
.shadow>*:nth-child(1) {
    position: absolute;
    left: 50%;
    transform: translate(-50%, -55%);
    top: 50%;
}
.shadow>*:nth-child(2) {
    position: absolute;
    bottom: 0px;
    width: 100%;
    text-align: right;
    background-image: linear-gradient(transparent,rgba(0,0,0,.5));
}
.shadow>*:nth-child(2)>div:nth-child(1) {
    padding-right: 5px;
    padding-bottom: 5px;
}
.relatedvidwrapper{
    display: block;
}
.vidtitle{
    font-size: 18px;
    font-weight: bold;
}
.imgdiv {
    min-height: 70px;
    max-height: 70px;
}
.img {
    min-height: 70px;
    max-height: 70px;
}

@media only screen and (max-width: 600px) {
    .grid_announcementtitle {
        display: grid;
        grid-template-columns: 1fr 1fr; /* Adjust min width as needed */
        /* place-items: center; */
        gap: 10px;
    
    }
    .grid_heiliao {
        display: grid;
        grid-template-columns: auto 1fr;
        gap: 5px;
        padding-bottom: 10px;
        
    }
    .xs_list_item {
        display: grid;
        grid-template-rows: 1fr 1fr;
        font-size: 13px;
    }
    .list_fushou {
        display: grid;
        grid-template-columns: repeat(5, minmax(0, 1fr));
    }
    .list_url {
        display: grid;
        grid-template-columns: repeat(4, minmax(0, 1fr));
        gap: 5px;
    }
    .imgdiv {
        min-height: 50px;
        max-height: 50px;
    }
    .img {
        min-height: 50px;
        max-height: 50px;
    }
    .centered_gif > img {
        max-height: 350px;
        max-width: 350px;
    }
    .mobilegap1{
        gap: 1px !important;
    }
    .mobilefont11{
        font-size: 11px;
    }
    .content_wrapper{
        padding: 0 10px;
    }
    .sub_title{
        font-size: 14px;
    }
    .vidtitle{
        font-size: 16px;
    }
    .vid_share {
        width: 50% !important;
    }
    .contentwrapper{
        padding-left: 10px;
        padding-right: 10px;
    }
    .relatedvidwrapper{
        /* display: none !important; */
        background: #F6F6F6;
    }
    .thumb_img_series_wrapper{
        /* display: block; */
        display: -webkit-inline-box;
        overflow: scroll;
    }
    .thumb_img_series_wrapper > div{
        width: 25%;
    }
    .thumb_series_wrapper{
        grid-template-columns: repeat(2, minmax(0, 1fr)) !important;
    }
    .thumb_series_wrapper_2{
        grid-template-columns: repeat(2, minmax(0, 1fr)) !important;
    }
    .tag_sty{
        justify-content: start !important; 
    }
    .rightvidtag{
        grid-template-columns: repeat(1, minmax(0, 1fr));
    }
    .vidc {
        grid-template-columns: 1fr !important;  
    }
    .buy_card {
        display: none;
    }
    .vid {
        width: 100%;
    }
    .gy {
        margin: 0 0 0 8px;
        max-width: 200px;
        min-width: 100px !important;
    }
    .bbspc {
        margin-right: 0px;
        display: none;
    }
    .bbsmob {
        display: block !important;
        margin-left: 0px !important;
        margin-right: 0px !important;
    }
    .img_p {
        display: flex;
        /* gap: 120px; */
        justify-content: space-between;
        padding-inline: 15px;
        margin-top: 20px;
    
    }
    .imgdetails {
        text-align: center;
    }
    .fontdet {
        color: #FF8E9D;
        padding-top: 10px;
    }
}

.bbsbtn {
    border-radius: 6px;
    background: linear-gradient(270deg,#fea803 0%,#ffd475 100%);
    /* background: linear-gradient(90deg, #FF9CD8 0%, #D763A9 100%); */
    color: white;
    font-weight: 500;
    padding: 10px;
    font-size: 14px;
    cursor: pointer;
    width: fit-content;
}
.bbspc {
    margin-right: 276px;
    margin-left: auto;
}
/* .bbsmob {
    display: none;
} */
