.m {
    padding: 10px;
    max-width: 1100px;
    margin: auto;
}
.sec {
    line-height: 25px;
    text-align: left;
    padding-top: 10px;
    color: #718083;
    font-size: 15px;
}

.grey_b {
    background-color: #FFEBF9;
    margin-top: 5px;
    margin-bottom: 5px;
    padding: 10px;
    padding-left: 20px;
}

.light_g {
    background-color: #FFEBF9;
    margin-top: 5px;
    margin-bottom: 5px;
    padding: 10px;
    padding-left: 20px;
}
.lt {
    color: #e77996;
}
.dt {
    color: #FF8E9D;
    cursor: pointer;
}
.dt_method2 {
    color: #FF8E9D;
    cursor: pointer;
    font-size: 15px;
}
.email {
    color: #A9A9A9;
    font-size: 16px;
    cursor: pointer;
    text-decoration: none;
}
.email_content {
    color: #A9A9A9;
    font-size: 16px;
    cursor: pointer;
    text-decoration: none;
}
.email_content_bak {
    color: #666666;
    font-size: 14px;
    cursor: pointer;
    text-decoration: none;
}
.dark_title {
    font-weight: 500;
    line-height: 30px;
    font-size: 15px;
    padding-right: 7px;
    color: #666666;
}
.dark_sub_title {
    font-weight: 400;
    /* line-height: 10px; */
    font-size: 14px;
    color: #666666;
}
.hover:hover {
    color: #e77996;
    cursor: pointer;
}

.f12_b {
    font-size: 12px;
    padding-bottom: 10px;
}
.l10 {
    padding-left: 10px;
}
.main_title {
    font-size: 20px;
    color: #FF8E9D;
    text-align: center;
    font-weight: 500;
    text-shadow: 0.5px 0.5px 0 #FF8E9D; /* Adjust the color and size to suit */

}

.sub_title {
    color: #FF8E9D;
    font-weight: 500;
    text-shadow: 0.4px 0.4px 0 #FF8E9D; /* Adjust the color and size to suit */
    font-size: 17px;
}

.padding {
    padding-top: 20px;
}

.grid_desc {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
}

.flex_desc {
    display: flex;
    gap: 40px;
    padding-block: 10px;
}
.flex {
    display: flex;
    gap: 100px;
}

.tougao {
    font-size: 22px;    
    color: #666666;
}

@media only screen and (max-width: 600px) {
    .dark_title {
        font-size: 14px;
    }
    .flex {
        display: block;
        gap: 0;
    }
    .sec {
        line-height: 18px;
        text-align: left;
        padding-top: 10px;
        color: #718083;
        font-size: 13px;
    }
    .dark_sub_title {
        font-weight: 400;
        line-height: 20px;
        font-size: 12px;
        color: #666666;
    }
    .sub_title {
        color: #FF8E9D;
        font-weight: 500;
        text-shadow: 0.4px 0.4px 0 #FF8E9D; /* Adjust the color and size to suit */
        font-size: 15px;
    }
    .flex_desc {
        display: block;
        gap: 40px;
        padding-block: 10px;
    }
    .light_g {
        background-color: #FFEBF9;
        margin-top: 5px;
        margin-bottom: 5px;
        padding: 10px;
        padding-left: 20px;
        margin-left: -10px;
        margin-right: -10px;
    }
    .grey_b {
        background-color: #FFEBF9;
        margin-top: 5px;
        margin-bottom: 5px;
        padding: 10px;
        padding-left: 20px;
        margin-left: -10px;
        margin-right: -10px;
    }
    .tougao {
        font-size: 15px;    
        color: #666666;
    }
}