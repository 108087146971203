.btop {
    margin-top: 72px;
    margin-bottom: 20px;
    padding: 10px 0;
}

.btop .icon{
    /* filter: invert(1); */
}

.btoppink{
    background: linear-gradient(90deg, rgb(251, 142, 183) 20%, rgb(234, 166, 199) 40%, rgb(217, 191, 217) 100%);
    color: #fff;
}

.btoppink .icon{
    filter: invert(0);
}

@media only screen and (max-width: 600px) {
    .btop {
        margin-top: 20px;
        font-size: 16px;
        font-weight: 500;
    }
}